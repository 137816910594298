import React, { lazy, useEffect, useContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import packageJson from "../package.json";
import PrivateRoute from "./privateRoute";
import { AuthContext } from "./context/AuthContext";

import { FcGoogle } from "react-icons/fc";
import { Modal, ModalBody } from "@windmill/react-ui";
import * as Sentry from "@sentry/react";
import "react-responsive-modal/styles.css";
import { Helmet } from "react-helmet";
import { getObjectFromLocalStorage, saveObjectInLocalStorage } from "./utils/localStorage";

const Success = lazy(() => import("./pages/Success"));

global.appVersion = packageJson.version;
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Page404 = lazy(() => import("./pages/404"));

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function App() {
  const { user } = useContext(AuthContext);
  const [extInstallModal, setExtInstallModal] = useState(false);

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.info(
            `We have a new version - ${latestVersion}. Should force refresh`
          );

          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
              for (let name of names) caches.delete(name);
            });
          }
          window.location.reload();
        } else {
          console.info(`[version:${latestVersion}]`);
        }
      });
  });

  useEffect(() => {
    if (user?.id) {
      const isDontShowExtention = getObjectFromLocalStorage("dontShowExtension");
      if (isDontShowExtention) {
        setExtInstallModal(false);
      } else {
        setTimeout(() => {
          const element = document.querySelector("[gn-extention-version]");
          if (!element?.getAttribute("gn-extention-version")) {
            setExtInstallModal(true);
          } else {
            setExtInstallModal(false);
          }
        }, 2000);
      }
    }
  }, [user]);

  const handleShowModal = () => {
    saveObjectInLocalStorage("dontShowExtension",true) 
    setExtInstallModal(false);
  };

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />

          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/404" component={Page404} />

          <PrivateRoute path="/success" component={Success} />
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </Router>

      {/* extension installed modal */}
      <Modal
        isOpen={extInstallModal}
        // onClose={}
        className="p-0 rounded-lg bg-white w-full max-w-2xl"
      >
        <ModalBody>
          <div className="p-8 text-center">
            <h4 className="text-xl font-bold mb-4">Installation</h4>

            <h6 className="text-3xl font-bold text-teal-700">
              Growth Nodes extension is not installed?
            </h6>

            <p className="text-gray-500 font-semibold mb-10">
              Please download it from here
            </p>

            <div className="grid grid-cols-12 items-stretch gap-4">
              <button
                onClick={handleShowModal}
                className="bg-gray-200 text-gray-700 p-3 w-full rounded col-span-5 focus:outline-none"
              >
                Don't show this message again
              </button>
              <a
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/social-media-crm-toolkit/ocopgnklkdgjomofiklloofpognlhgic"
                target="_blank"
                className="bg-white p-3 w-full rounded border border-gray-300 hover:shadow-lg focus:outline-none col-span-7"
              >
                <FcGoogle className="text-xl inline-block mr-2" /> Download
                chrome extension
              </a>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Sentry.withProfiler(App);
