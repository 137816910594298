import { FaCog } from "react-icons/fa";

const PERSONAL_TEAM = {
  name: "personal",
  id: null,
};

const PROFILE_DROPDOWN_ITEMS = [
  // {
  //   id: 1,
  //   label: "profile settings",
  //   path: "/settings/profile",
  //   icon: FaUser,
  // },
  {
    id: 1,
    label: "settings",
    path: "/settings",
    icon: FaCog,
  },
];

function validMail(mail) {
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
    mail
  );
}

export { PERSONAL_TEAM, PROFILE_DROPDOWN_ITEMS, validMail };
